<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row>
        <b-col lg="8" md="12" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Dosya Yöneticisi</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-50">
            <table class="table table-striped table-responsive-md">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  DOSYA ADI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  DOSYA AÇIKLAMASI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  İNDİRME LİNKİ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(
                    dosya, index
                  ) in this.$store.state.webPanel.dosyaYoneticisi
                    .slice()
                    .reverse()"
                  :key="index"
              >
                <td>{{ dosya.dosya }}</td>
                <td>{{ dosya.aciklama }}</td>
                <td><a class="text-darkk" :href="imagePath(dosya.dosya)" target="_blank">
                  {{ imagePath(dosya.dosya) }}
                </a></td>
                <td>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      @click="dosyaRemove(dosya.dosyaID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col lg="4" md="12" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Dosya Ekle</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-50">
            <b-form-group
                label="Dosya Açıklaması"
                label-for="basicInput"
            >
              <b-form-input
                  v-model="aciklama"
                  placeholder="Dosya Açıklaması Giriniz"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="Dosya"
                label-for="basicInput"
            >
              <b-form-file
                  type="file"
                  placeholder="Dosya Seçiniz"
                  drop-placeholder="Drop file here..."
                  @change="onFileSelected"
              />
            </b-form-group>
            <b-row>
              <b-col
                  cols="12"
                  class="text-right"
              >
                <b-progress
                    v-model="value"
                    max="100"
                />
              </b-col>
              <b-col
                  cols="12"
                  class="text-right"
              >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="bgInfo"
                    class="border-0 mt-50"
                    :style="{backgroundColor:$store.getters.bgSuccess}"
                    @click="onUpload"
                >
                  Dosya Yükle
                </b-button>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import {
  BProgress,
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BProgress,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    BFormFile,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      aciklama: '',
      selectedFile: null,
      value: 0,
    }
  },
  beforeDestroy() {
    this.$store.commit('clearDosyaYoneticisi')
  },
  created() {
    this.$store
        .dispatch('dosyaYoneticisi')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  methods: {
    imagePath(path) {
      return (
          `${this.$store.state.API_URL}userFiles/${localStorage.getItem('otelFirmaKodu')
          }/Upload/${
              path}`
      )
    },
    onUpload() {
      const fd = new FormData()
      fd.append('dosya', this.selectedFile)
      fd.append('serviceName', 'web')
      fd.append('methodName', 'dosyaYoneticisi')
      fd.append('aciklama', this.aciklama)
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('dosyaKaydi', true)
      axios
          .post(store.state.POST_URL, fd, {
            onUploadProgress: function (progressEvent) {
              this.value = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 95),
              )
            }.bind(this),
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.data.result.status == 200) {
              this.aciklama = ''
              this.$store.dispatch('dosyaYoneticisi')
              this.value = 0
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {

            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.value = 0
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'danger',
                },
              })
            }
          })

      // this.$store
      //   .dispatch("dosyaSave", {
      //     veri: this.selectedFile,
      //     aciklama: this.aciklama,
      //   })
      //   .then((res, position = localStorage.getItem("successReturn")) => {
      //     this.selectedFile = null;
      //     this.$store.dispatch("dosyaYoneticisi");
      //     this.aciklama = "";
      //     const mesaj = this.$store.getters.notificationSettings(res.data);
      //     this.$toast(
      //       {
      //         component: ToastificationContent,
      //         props: {
      //           title: "Başarılı",
      //           text: mesaj,
      //           icon: "ThumbsUpIcon",
      //           variant: "success",
      //         },
      //       },
      //       { position }
      //     );
      //   })
      //   .catch((err) => {
      //     const mesaj = this.$store.getters.notificationSettings(err);
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata!",
      //         text: mesaj,
      //         icon: "ThumbsDownIcon",
      //         variant: "danger",
      //       },
      //     });
      //   });
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0]
    },
    dosyaRemove(val) {
      this.$store
          .dispatch('dosyaRemove', {
            sil: val,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Dosya Silindi',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
}
</script>
<style scoped>
.table th,
.table td {
  padding: 3px;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.row[data-v-66016ac9] {
  justify-content: end;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #93bee1 !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.card-body {
  padding: 10px 15px;
}

.text-darkk {
  color: black !important;
}
</style>
